.heading1 {
  font-family: var(--default-font-family);
  font-style: normal;
  font-weight: var(--font-weight-semibold);
  font-size: var(--text-3xl);
  line-height: var(--text-3xl--line-height);
  letter-spacing: var(--tracking-1);
}

.heading2 {
  font-family: var(--default-font-family);
  font-style: normal;
  font-weight: var(--font-weight-semibold);
  font-size: var(--text-2xl);
  line-height: var(--text-2xl--line-height);
  letter-spacing: var(--tracking-1);
}

.heading2-subtitle {
  font-family: var(--default-font-family);
  font-style: normal;
  font-weight: var(--font-weight-medium);
  font-size: 12px;
  line-height: 24px;
  letter-spacing: var(--tracking-1);
  color: #4e555f;
}

.heading3 {
  font-family: var(--default-font-family);
  font-style: normal;
  font-weight: var(--font-weight-semibold);
  font-size: var(--text-xl);
  line-height: var(--text-xl--line-height);
  letter-spacing: var(--tracking-1);
}

.body-primary {
  font-family: var(--default-font-family);
  font-style: normal;
  font-weight: var(--font-weight-normal);
  font-size: 14px;
  line-height: 24px;
  color: #363b45;
}

.body-secondary {
  font-family: var(--default-font-family);
  font-style: normal;
  font-weight: var(--font-weight-normal);
  font-size: 12px;
  line-height: 20px;
  color: #555d6d;
}
