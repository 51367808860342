.medium-font() {
  font-weight: var(--font-weight-medium);
}

.regular-font() {
  font-weight: var(--font-weight-normal);
}

.icon {
  display: inline-block;
  background-repeat: no-repeat !important;
}

.droppable-border() {
  position: absolute;
  z-index: 1;
  top: 4px;
  left: 4px;
  bottom: 4px;
  right: 4px;
  border-radius: 10px;
  background-image: url("data:image/svg+xml;utf8,<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' style='fill: none; stroke: rgb(172,213,255); stroke-width: 4; stroke-dasharray: 15 11'/></svg>");
}

.disabled {
  pointer-events: none;
  color: #ccc;
  background-color: unset;
}
